[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="mode-light"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7a52f4;
  --saas-main-color: #4e2c5b;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #bc20fe;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ed3ee;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #a833cc;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #cfbfff;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #4a4c51;
  --login-bg2: #2e1b3f;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
[data-theme="summer-capital-mode"] {
  --main-bg-color: #000;
  --main-color: #d9a527;
  --saas-main-color: #693a0f;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #d9a527;
  --saas-table-btn-bg1: rgba(217, 165, 39, 0.2);
  --saas-table-btn-border2: #eb892e;
  --saas-table-btn-bg2: rgba(235, 137, 46, 0.2);
  --saas-product-label-bg1: #daa729;
  --saas-product-label-bg2: #693a10;
  --saas-investor-label-bg: rgba(217, 165, 39, 0.2);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #ffdd8c;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #ffe7ac;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #6c3500;
  --login-bg2: #201102;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.YBjmqbui {
  max-width: 1200px;
  margin: auto;
}
.YBjmqbui .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.YBjmqbui .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.YBjmqbui .ant-btn:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.YBjmqbui .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.YBjmqbui .ant-table-thead > tr > th,
.YBjmqbui .ant-table-sticky-holder {
  background-color: var(--table-heading-bg);
}
.YBjmqbui .pJA4xEbL {
  padding: 0 24px 24px;
  background: var(--tab-active-bg);
  min-height: 600px;
}
.YBjmqbui .pJA4xEbL .__0Px4_HsW {
  width: 95px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  padding-top: 0;
  padding-bottom: 0;
}
.YBjmqbui .pJA4xEbL .Ure8ibSs {
  margin-right: 8px;
}
.YBjmqbui .pJA4xEbL .Zwo1ipNc {
  display: inline-block;
  width: 200px;
}
.YBjmqbui .rCwEmBJB {
  width: 160px;
  margin-right: 0px;
  color: var(--white);
  font-family: var(--CommonMedium);
}
.YBjmqbui .z9KgZIWH {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.YBjmqbui .z9KgZIWH .ant-table-thead > tr > th {
  padding: 6px 16px;
  height: 57px;
}
.YBjmqbui .z9KgZIWH .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.YBjmqbui .z9KgZIWH .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 180px !important;
  height: 5px;
}
.YBjmqbui .z9KgZIWH .ant-tabs-top > .ant-tabs-nav,
.YBjmqbui .z9KgZIWH .ant-tabs-bottom > .ant-tabs-nav,
.YBjmqbui .z9KgZIWH .ant-tabs-top > div > .ant-tabs-nav,
.YBjmqbui .z9KgZIWH .ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
}
.YBjmqbui .z9KgZIWH .ant-tabs-top > .ant-tabs-nav::before,
.YBjmqbui .z9KgZIWH .ant-tabs-bottom > .ant-tabs-nav::before,
.YBjmqbui .z9KgZIWH .ant-tabs-top > div > .ant-tabs-nav::before,
.YBjmqbui .z9KgZIWH .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  content: "";
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 {
  max-width: 1200px;
  background: var(--module-bg);
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP {
  padding: 0 24px 32px;
  min-height: 600px;
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP .UNcZP0Ik {
  margin-right: 16px;
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP .t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP .t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP .iQWkKsnA {
  min-height: 600px;
  margin-bottom: 30px;
  padding-top: 25px;
}
.YBjmqbui .z9KgZIWH .Wabba125 {
  max-width: 1200px;
  background: var(--module-bg);
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL {
  padding: 25px 24px 32px;
  min-height: 600px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL .UNcZP0Ik {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL .t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL .t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL .iQWkKsnA {
  min-height: 600px;
  margin-bottom: 30px;
}
.YBjmqbui .__0z33Lr69 {
  background-color: var(--module-bg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.YBjmqbui .qfhXkYpN {
  height: 64px;
  background-color: var(--module-bg);
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab {
  margin: 0;
  width: 180px;
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab-btn {
  padding-top: 5px;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-family: var(--CommonRegular);
  text-align: center;
  color: var(--text-color);
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab-btn:hover {
  color: var(--white);
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab-active {
  background-color: var(--tab-active-bg);
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.YBjmqbui .NawuxfSj {
  padding-top: 24px;
}
.YBjmqbui .NawuxfSj .Ure8ibSs {
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.YBjmqbui .NawuxfSj .jTRlEiWc {
  display: flex;
  align-items: center;
}
.YBjmqbui .NawuxfSj .IJk5p_UY {
  background: var(--btn-grey-bg);
  width: 75px;
  height: 22px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.YBjmqbui .NawuxfSj .UNcZP0Ik {
  height: 28px;
  padding: 0 17px;
}
.YBjmqbui .NawuxfSj .PSc2eg_E {
  width: 100px;
  height: 28px;
  background-color: var(--btn-grey-bg);
  border-radius: 2px;
}
.YBjmqbui .NawuxfSj .PSc2eg_E .dXx7H2wp .ant-statistic-content {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.YBjmqbui .NawuxfSj .PSc2eg_E .dXx7H2wp .ant-statistic-content-value {
  color: var(--main-color);
  font-size: 14px;
}
.YBjmqbui .NawuxfSj .iQWkKsnA {
  min-height: unset;
  margin-bottom: 30px;
}
.YBjmqbui .NawuxfSj .iQWkKsnA .ant-table-content {
  min-height: unset;
}
.YBjmqbui .NawuxfSj .t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.YBjmqbui .NawuxfSj .t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.YBjmqbui .NawuxfSj .B30vWBx_ {
  position: absolute;
  top: 0;
  left: 0;
}
.YBjmqbui .NawuxfSj .__6jg74aTR {
  position: absolute;
  top: -3px;
  left: 0;
}
.SLdhin5O {
  font-family: var(--CommonMedium);
  color: var(--white);
}
.SLdhin5O .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.SLdhin5O .ant-btn-primary {
  color: var(--white);
  margin-right: 30px;
}
.SLdhin5O .ant-btn:hover,
.SLdhin5O .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.SLdhin5O .__8FEZYofc {
  margin-top: 19px;
  padding: 40px 80px;
  background-color: var(--module-bg);
}
.SLdhin5O .__8FEZYofc .mD6V9dd_ {
  padding-bottom: 31px;
  font-size: 22px;
}
.SLdhin5O .__8FEZYofc .__0Lic9IXu {
  padding-bottom: 24px;
  font-size: 16px;
  color: var(--white);
}
.SLdhin5O .__8FEZYofc .H2ggnByL {
  padding: 0 20px;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .ZtdzZ3D_ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .ZtdzZ3D_ .Ure8ibSs {
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .ZtdzZ3D_ .KcGLEqoC {
  color: var(--text-color);
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .ZtdzZ3D_ .jTRlEiWc {
  color: var(--white);
  font-weight: bold;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .Rr2vZsAm {
  height: 44px;
  line-height: 44px;
  color: var(--white);
  font-size: 16px;
}
.SLdhin5O .__8FEZYofc .sMi4Iq6D {
  font-size: 14px;
  color: var(--text-color);
  padding-bottom: 13px;
}
.SLdhin5O .__8FEZYofc .dlf_rhLZ {
  width: 45%;
  font-size: 14px;
  color: var(--text-color) !important;
  background-color: var(--main-bg-color);
}
.SLdhin5O .__8FEZYofc .u4gAWsHY {
  margin-top: 18px;
  font-size: 16px;
  padding-bottom: 8px;
  font-family: var(--CommonRegular);
  color: var(--white);
}
.SLdhin5O .__8FEZYofc .h_FnMYX6 {
  padding-bottom: 24px;
  font-size: 24px;
  color: var(--white);
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb p {
  font-family: var(--CommonRegular);
  color: var(--text-color);
  margin-bottom: 9px;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .eYAOUeKd {
  width: 45%;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .eYAOUeKd .sqOaUj7B {
  height: 10px;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .eYAOUeKd .sqOaUj7B p {
  color: var(--tip-text-color);
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .qYQL3I8z {
  width: 45%;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .qYQL3I8z .dlf_rhLZ {
  width: 100%;
  font-size: 14px;
  color: var(--text-color) !important;
  background-color: var(--main-bg-color);
}
.SLdhin5O .__8FEZYofc .FFOKD3vX {
  margin-top: 48px;
}
.q_Cpqm_J {
  font-family: var(--CommonMedium);
  color: var(--white);
}
.q_Cpqm_J .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.q_Cpqm_J .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.q_Cpqm_J .ant-btn:hover,
.q_Cpqm_J .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.q_Cpqm_J .__8FEZYofc {
  margin-top: 25px;
  padding: 40px;
  background-color: var(--module-bg);
}
.q_Cpqm_J .__8FEZYofc .mD6V9dd_ {
  font-size: 22px;
  color: var(--white);
  margin-bottom: 30px;
}
.q_Cpqm_J .__8FEZYofc .UNcZP0Ik {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.q_Cpqm_J .__8FEZYofc .t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.q_Cpqm_J .__8FEZYofc .t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.q_Cpqm_J .__8FEZYofc .iQWkKsnA {
  min-height: 600px;
  margin-bottom: 30px;
}
.aqip_EX4 .rRKPSwHY {
  font-size: 24px;
  color: var(--white);
  font-weight: bold;
}
.aqip_EX4 .lJ6hjJ4Z .vG05vdPy {
  font-family: var(--CommonRegular);
  counter-reset: var(--text-color);
  margin-bottom: 16px;
}
.aqip_EX4 .lJ6hjJ4Z .X8AOAciQ {
  margin-bottom: 16px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.aqip_EX4 .EA0pw4VD p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.aqip_EX4 .EA0pw4VD p .FcS_LZcF {
  color: var(--text-color);
}
.aqip_EX4 .EA0pw4VD p .E3PvYQTb {
  color: var(--white);
}
.XbiU27Ty .ant-input-suffix:after {
  content: "";
  width: 1px;
  height: 14px;
  margin-left: 12px;
  background: var(--white);
  opacity: 0.1;
}
.XbiU27Ty .ant-modal-content .ant-input-affix-wrapper {
  padding-right: 0;
}
.XbiU27Ty .ant-input-group-addon {
  padding-left: 0;
  padding-right: 0;
}
.XbiU27Ty .ant-modal-title {
  font-size: 16px;
  color: var(--white);
}
.XbiU27Ty .quO5An1w {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-right: 10px;
  max-width: 200px;
}
.XbiU27Ty .oB7jYLRs {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
  max-width: 200px;
}
.XbiU27Ty .b5VwNgX3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.XbiU27Ty .PxZM11VU {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  margin-bottom: 26px;
  margin-top: 10px;
}
.XbiU27Ty .wNsiLFxm {
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--border-color1);
  margin-right: 8px;
  padding: 0 10px;
  flex-grow: 1;
}
.XbiU27Ty ._cUukzOA {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.XbiU27Ty .SePn3Tvz {
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 21px;
  margin-top: 18px;
  margin-bottom: 86px;
}
.XbiU27Ty .Cn2dHLuS {
  font-size: 12px;
  color: var(--text-color-fourth);
}
.khgBTj9w {
  padding-top: 24px;
}
.khgBTj9w .UNcZP0Ik {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.khgBTj9w .UNcZP0Ik:focus {
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.khgBTj9w .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.khgBTj9w .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.khgBTj9w .ant-btn:hover,
.khgBTj9w .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.khgBTj9w .ant-switch {
  background-color: var(--btn-grey1-bg);
  background-image: none;
}
.khgBTj9w .ant-switch-checked {
  background-color: var(--main-color);
}
.khgBTj9w .ant-switch-handle::before {
  background-color: var(--tab-active-bg);
}
.khgBTj9w .ant-table-content {
  min-height: 235px;
}
.khgBTj9w .Ure8ibSs {
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.khgBTj9w .jTRlEiWc {
  display: flex;
  align-items: center;
}
.khgBTj9w .Tzs8IqEA {
  padding: 3px 16px;
  height: 28px;
  text-align: center;
  display: inline-block;
  background: var(--btn-grey-bg);
  border-radius: 14px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
}
.khgBTj9w .iQWkKsnA {
  min-height: 600px;
  margin-bottom: 30px;
}
.EkRCdrXv .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: none;
  padding: 0;
}
.EkRCdrXv .ant-modal-footer button:first-child {
  display: inline-block !important;
  margin-left: 16px;
  order: 1;
}
.EkRCdrXv .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: var(--main-bg-color);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  border: 1px solid var(--btn-border);
  border-radius: 4px;
  margin-top: 20px;
}
.EkRCdrXv .ant-modal-footer .ant-btn-primary {
  background: var(--main-color) !important;
  color: var(--white);
}
.EkRCdrXv .ant-modal-footer .ant-btn-primary:hover {
  background: var(--main-btn-hover-color) !important;
}
.EkRCdrXv .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.aqip_EX4 .vG05vdPy {
  font-family: var(--CommonRegular);
  counter-reset: var(--text-color);
  margin-bottom: 16px;
}
.aqip_EX4 .X8AOAciQ {
  margin-bottom: 16px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.aqip_EX4 .CkYfRM32 p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.aqip_EX4 .CkYfRM32 p .D089DBNo {
  font-family: var(--CommonRegular);
  color: var(--text-color);
}
.aqip_EX4 .CkYfRM32 p .VrZdp0fx {
  color: var(--white);
  font-family: var(--CommonMedium);
}
.aqip_EX4 .l9aNQygn {
  display: flex;
  flex-direction: row;
}
.aqip_EX4 .l9aNQygn .VU0BD_Gb {
  color: var(--white);
  margin-right: 8px;
}
.TyCjOosl {
  font-family: var(--CommonMedium);
  color: var(--white);
}
.TyCjOosl .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.TyCjOosl .ant-btn-primary {
  color: var(--white);
  margin-right: 30px;
}
.TyCjOosl .ant-btn:hover,
.TyCjOosl .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.TyCjOosl .__8FEZYofc {
  margin-top: 19px;
}
.TyCjOosl .__8FEZYofc .eQ9UksQQ {
  position: absolute;
  top: -5px;
  left: -5px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n {
  padding: 41px 63px;
  background-color: var(--module-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .mD6V9dd_ {
  font-size: 22px;
  color: var(--white);
  margin-bottom: 36px;
  display: flex;
  align-items: center;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .mD6V9dd_ .Ure8ibSs {
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof {
  display: flex;
  justify-content: space-between;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .NYmUAumm {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 20px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .M1UQPb_C {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .M1UQPb_C .__0A2r0ftE {
  color: var(--text-color);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .M1UQPb_C .azeRYjmq {
  color: var(--white);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt {
  width: 45%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .oRM9Tb8C {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 8px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .__42_3p0EQ {
  font-size: 24px;
  color: var(--white);
  font-family: var(--CommonBold);
  margin-bottom: 24px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm {
  display: flex;
  position: relative;
  background-color: var(--main-bg-color);
  padding-right: 19px;
  border-radius: 2px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm .__50p13ZtW {
  width: 100%;
  background-color: var(--main-bg-color);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm .__50p13ZtW input::-webkit-outer-spin-button,
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm .__50p13ZtW input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm .AGMHMpM5 {
  position: absolute;
  right: 19px;
  height: 40px;
  line-height: 40px;
  color: var(--main-color);
  cursor: pointer;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .M3fJAT0W {
  color: var(--tip-text-color);
  margin-bottom: 14px;
  font-family: var(--CommonRegular);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .l9aNQygn .VU0BD_Gb {
  margin: 0 9px 0 16px;
  color: var(--white);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .yju5mPm9 {
  margin-top: 56px;
  display: flex;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL {
  width: 50%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 {
  font-size: 14px;
  display: flex;
  margin-bottom: 20px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .eZ4Oj0Lt {
  width: 29%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .eZ4Oj0Lt .BT9_lgnY {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: var(--btn-green-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .eZ4Oj0Lt .BT9_lgnY .pDxTDo1n {
  text-align: start;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .gVJvvDcu {
  width: 39%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .gVJvvDcu .c_J8MuuZ {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: var(--btn-red-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .gVJvvDcu .r8I3__l5 {
  text-align: center;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .HGS7tdXc {
  width: 32%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .HGS7tdXc .oRM9Tb8C {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: var(--main-color);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .HGS7tdXc .__2BKBgL_S {
  text-align: end;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z {
  margin: 22px 0;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr {
  margin-top: 14px;
  font-size: 12px;
  display: flex;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .Nz1ExWbc .ant-row {
  width: 130px;
  height: 32px;
  align-items: center;
  padding-left: 10px;
  border-right: 1px solid var(--module-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .Nz1ExWbc .ant-row:nth-child(odd) {
  background: var(--main-bg-color);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM {
  width: calc(100% - 130px);
  color: var(--white);
  overflow-x: scroll;
  /* 设置滚动条的样式 */
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM::-webkit-scrollbar {
  height: 3px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM .ant-row {
  flex-wrap: nowrap;
  height: 32px;
  align-items: center;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM .ant-row > .ant-col {
  flex: 1;
  flex-basis: 105px;
  flex-shrink: 0;
  text-align: center;
  line-height: 32px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM .ant-row > .ant-col:not(:first-child) {
  border-left: 1px solid var(--module-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM .ant-row:nth-child(odd) > .ant-col {
  background: var(--main-bg-color);
}
.K4pDZ1WO .uOhykmGE ._cUukzOA {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.K4pDZ1WO .uOhykmGE ._cUukzOA .quO5An1w {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-right: 10px;
}
.K4pDZ1WO .uOhykmGE ._cUukzOA .nFA7KnW3 {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.GT4tb0mf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.GT4tb0mf .yogQrEpB {
  margin-left: 6px;
  margin-right: 12px;
}
.GT4tb0mf .k38zliHX {
  text-decoration: line-through;
  color: var(--remark-text-color);
}
.cWobm_gF .__1M_Qzw {
  width: 100%;
  display: flex;
  height: 26px;
  color: var(--white);
}
.cWobm_gF .__1M_Qzw > div {
  flex: 1;
  text-align: center;
}
.cWobm_gF .__1M_Qzw div.__0FhLHlFV {
  background: var(--btn-green-bg);
}
.cWobm_gF .__1M_Qzw div.luyi_Nv9 {
  background: var(--btn-red-bg);
}
.cWobm_gF .__1M_Qzw div.auWnhAVN {
  background: var(--main-color);
}
.cWobm_gF .UaSyWRpU {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 12px;
  margin-bottom: 10px;
}
.cWobm_gF .UaSyWRpU > div {
  flex: 1;
}
.cWobm_gF .UaSyWRpU > div > div.nWzcomof {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.cWobm_gF .lgPV1V8B {
  margin-top: 14px;
  padding: 24px 22px;
  background-color: var(--module-bg);
  color: var(--remark-text-color);
}
.cWobm_gF .lgPV1V8B > p:not(:first-child) {
  margin-top: 30px;
}
.cWobm_gF .l9aNQygn {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.cWobm_gF .l9aNQygn .VU0BD_Gb {
  color: var(--white);
  margin-left: 16px;
}
.cWobm_gF .l9aNQygn .VU0BD_Gb.uofWnVs2 {
  color: var(--text-color);
}
.LlxnB8Xl {
  display: flex;
}
.LlxnB8Xl .k1d_7yai {
  width: 500px;
  height: 238px;
}
.LlxnB8Xl .pcN_rhdP {
  cursor: pointer;
}
.LlxnB8Xl .__2ddPUux_ {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.LlxnB8Xl .PIegL1cI {
  display: flex;
  height: 74px;
  margin-top: 8px;
  padding: 14px 24px;
  background: var(--module-bg);
}
.LlxnB8Xl .PIegL1cI:first-child {
  margin-top: 0px;
}
.LlxnB8Xl .__52Tb2SIw {
  font-family: var(--CommonBold);
  font-size: 16px;
  color: var(--text-color);
}
.LlxnB8Xl .D7y72Pv_ {
  margin-top: 4px;
  font-size: 12px;
  color: var(--remark-text-color);
}
.LlxnB8Xl .UNcZP0Ik {
  align-self: center;
  padding: 0;
  font-size: 14px;
  width: 119px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid var(--main-color);
  color: var(--white);
  background: var(--main-color);
}
.LlxnB8Xl .UNcZP0Ik:hover {
  background: var(--main-btn-hover-color);
}
.uM8Fsg58 {
  color: var(--white);
  font-size: 16px;
}
.uM8Fsg58 .ant-input-group-addon {
  padding: 0 11px 0 0;
  font-size: 14px;
  font-family: var(--CommonMedium);
  color: var(--main-color);
  background: var(--main-bg-color);
  cursor: pointer;
}
.uM8Fsg58 .ant-input-group-wrapper {
  background: var(--main-bg-color);
  border-radius: 4px;
}
.uM8Fsg58 .ant-input-affix-wrapper {
  padding: 0 11px;
  background: var(--main-bg-color);
}
.uM8Fsg58 .ant-input-affix-wrapper:focus,
.uM8Fsg58 .ant-input-affix-wrapper-focused {
  border-width: 0;
  box-shadow: none;
}
.uM8Fsg58 .ant-input-suffix {
  font-family: var(--CommonMedium);
  color: var(--white);
}
.uM8Fsg58 .DFvXWPo1 {
  width: 1px;
  height: 14px;
  margin-right: 11px;
  background: var(--white);
  align-self: center;
  opacity: 0.1;
}
.uM8Fsg58 .__8FEZYofc {
  margin-top: 19px;
  padding: 40px 80px;
  background-color: var(--module-bg);
}
.uM8Fsg58 .__8FEZYofc .__95yaqrxa {
  display: flex;
}
.uM8Fsg58 .__8FEZYofc .pmM8f_am {
  flex: 1;
}
.uM8Fsg58 .__8FEZYofc .eWh0AoEJ {
  flex: 1;
  padding-left: 80px;
}
.uM8Fsg58 .mD6V9dd_ {
  padding-bottom: 31px;
  font-size: 22px;
  font-family: var(--CommonMedium);
}
.uM8Fsg58 .lfVddJnR {
  width: 480px;
  height: 60px;
  margin-top: 24px;
  font-size: 18px;
  color: var(--main-color);
  border: 1px dashed var(--main-color);
  border-radius: 4px;
  padding: 18px 180px;
  cursor: pointer;
}
.uM8Fsg58 .Biu5t9U_ {
  width: 480px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv {
  width: 431px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  background: var(--main-bg-color);
  font-family: var(--CommonBold);
  font-size: 18px;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .PB7RxPxD {
  width: 80px;
  margin-left: 12px;
  cursor: pointer;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .bhS_y5d9 {
  font-size: 10px;
  cursor: pointer;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .HL21J0Yz {
  margin-left: 40px;
  color: var(--border-color2);
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .f_wVjUmQ {
  width: 170px;
  margin-left: 20px;
  margin-right: 20px;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .lRQv_XiF {
  font-family: var(--CommonBold);
  font-size: 18px;
  text-align: center;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .fGyGQdu7 {
  font-size: 22px;
}
.uM8Fsg58 .Biu5t9U_ .h4BOLJBR {
  background: var(--main-bg-color);
  font-family: var(--CommonBold);
  font-size: 18px;
  margin-left: 14px;
  padding: 6px 10px;
  border-radius: 19px;
  cursor: pointer;
}
.uM8Fsg58 .SJzLTIwS {
  color: var(--red-text-color);
  font-size: 14px;
}
.uM8Fsg58 .Fk0L4Dhd {
  position: relative;
  font-size: 16px;
  height: 89px;
  margin-top: 16px;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid var(--main-color);
  background: var(--main-bg-color);
  cursor: pointer;
}
.uM8Fsg58 .Fk0L4Dhd .CiYV623K {
  font-family: var(--CommonBold);
}
.uM8Fsg58 .Fk0L4Dhd .ZPHk_6sy {
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  color: var(--remark-text-color);
}
.uM8Fsg58 .Fk0L4Dhd .XpbhjMGE {
  position: absolute;
  top: 0;
  right: 0;
}
.uM8Fsg58 .dlf_rhLZ {
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  color: var(--text-color) !important;
  background-color: var(--main-bg-color);
}
.uM8Fsg58 .u4gAWsHY {
  margin: 24px 0 18px 0;
}
.uM8Fsg58 .oZlmtVgX {
  font-family: var(--CommonMedium);
  font-size: 32px;
}
.uM8Fsg58 .D76nyfEL {
  color: var(--main-color);
  margin-top: 8px;
}
.uM8Fsg58 .R6f0HfNX {
  margin: 40px 0 8px 0;
}
.uM8Fsg58 .b_5JL8Ky {
  margin: 24px 0 8px 0;
}
.uM8Fsg58 .U7BTFzDy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.uM8Fsg58 ._LV7tcvi {
  font-family: var(--CommonRegular);
  font-size: 12px;
  color: var(--remark-text-color);
}
.uM8Fsg58 .__95yaqrxa {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 4px 0;
}
.uM8Fsg58 .__95yaqrxa .tp65MYGR {
  display: flex;
  align-items: center;
  color: var(--remark-text-color);
}
.__2aaLcN8g {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.__2aaLcN8g .Biu5t9U_ {
  align-items: center;
  width: 46%;
  padding: 10px 17px;
  margin-bottom: 16px;
  display: flex;
  background: var(--module-bg);
  cursor: pointer;
}
.__2aaLcN8g .Biu5t9U_ .jTRlEiWc {
  flex: 1;
  font-family: var(--CommonBold);
  font-size: 18px;
  margin-left: 15px;
}
.__2aaLcN8g .Biu5t9U_ .J2XeY5FF {
  font-size: 18px;
}
.__8aJ9_iXu .__95yaqrxa {
  display: flex;
}
.__8aJ9_iXu .__9TMFRrXN {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 0;
}
.__8aJ9_iXu .__9TMFRrXN .tp65MYGR {
  color: var(--remark-text-color);
}
.__8aJ9_iXu .__9TMFRrXN .QJDKb0_I {
  color: var(--white);
  font-family: var(--CommonMedium);
}
.mbmMnT_w {
  padding-top: 24px;
  width: 100%;
}
.mbmMnT_w button.UNcZP0Ik {
  width: 100% !important;
  padding: 16px 24px !important;
  background: var(--module-bg) !important;
  border-radius: 4px !important;
  border: none !important;
  height: auto !important;
}
.mbmMnT_w button.UNcZP0Ik:not(:last-child) {
  margin-bottom: 16px !important;
}
.mbmMnT_w .__9DzqPR27 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mbmMnT_w .__9DzqPR27 .HJk51QS2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mbmMnT_w .__9DzqPR27 .__52Tb2SIw {
  font-family: var(--CommonBold);
  font-size: 16px;
  color: var(--text-color);
}
.mbmMnT_w .__9DzqPR27 .D7y72Pv_ {
  margin-top: 8px;
  font-size: 12px;
  color: var(--remark-text-color);
  word-break: break-word;
  white-space: normal;
  text-align: left;
}
.KOEaOGDB {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-bottom: 15px;
}
.__9Y5sEkTA {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.__9Y5sEkTA:nth-child(even) {
  background-color: var(--tab-active-bg);
}
.d5mubJVh {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.__2CH4kUzO {
  margin-left: 17px;
  display: flex;
  flex-direction: column;
}
.tfdHVoWN {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 19px;
}
.tfdHVoWN .qEql4GmE {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.hsLGTL5N {
  width: 1px;
  background-color: var(--divider-color);
}
.lziS27MI {
  flex: 1;
  padding: 7px 0;
  text-align: center;
  font-size: 10px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  color: var(--text-color);
  line-height: 22px;
  margin-right: 1px;
}
.arlm_Dmz {
  flex: 1;
  padding: 7px 0;
  text-align: center;
  font-size: 10px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.WCmbQNgA {
  display: flex;
  align-items: center;
}
.__6AWxLrKR {
  margin-top: 16px;
  font-family: var(--CommonMedium);
  font-size: 14px;
  line-height: 22px;
  color: var(--white);
}
.t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
  padding-top: 24px;
}
.t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.ysq0yUtr {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}
.ysq0yUtr .Sh3GbXee {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 50px;
  margin-top: 28px;
}
.ysq0yUtr .ant-btn {
  border-radius: 20px;
  margin-right: 0;
}
.CVkhmya5 .vP9Id4pS {
  background-color: var(--main-bg-color);
  margin: -33px -24px 0;
  height: 16px;
}
.CVkhmya5 .nWzcomof {
  padding-top: 32px;
  padding-bottom: 60px;
  display: flex;
}
.CVkhmya5 .nWzcomof .pmM8f_am {
  flex: 418;
  padding: 0 22px;
}
.CVkhmya5 .nWzcomof .EWzv6S19 {
  width: 1px;
  height: 249px;
  background-color: var(--btn-type1-bg);
  flex: 0 0 auto;
  align-self: flex-end;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ {
  flex: 782;
  padding: 0 22px;
  margin-bottom: -45px;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .q5vS1mUO {
  color: var(--grey-text-color2);
  font-family: var(--CommonMedium);
  font-size: 18px;
  line-height: 18.495px;
  margin-bottom: 38px;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg {
  display: flex;
  align-items: center;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg:nth-child(n + 2) {
  margin-left: 47px;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg .__1ToLI4W_ {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--main-color);
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg .__1ToLI4W_.ZPr6uuS6 {
  background-color: var(--blue2);
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg .VCZyv34S {
  color: var(--white);
  font-size: 12px;
  line-height: 18px;
  margin-left: 12px;
}
.DkmGxcL_ .mD6V9dd_ {
  font-family: var(--CommonBold);
  font-size: 24px;
  color: var(--grey-text-color2);
}
.DkmGxcL_ .__7m5JfpI4 {
  display: flex;
  align-items: center;
  margin-top: 38px;
}
.DkmGxcL_ .__7m5JfpI4 .Qsi5H_Oh {
  margin-left: 8.7px;
}
.DkmGxcL_ .__7m5JfpI4 .Qsi5H_Oh .PB7RxPxD {
  font-family: var(--CommonBold);
  font-size: 18px;
  line-height: 22px;
  color: var(--white-op3);
}
.DkmGxcL_ .__7m5JfpI4 .Qsi5H_Oh .jTRlEiWc {
  font-size: 14px;
  color: var(--white-op3);
  margin-top: 4px;
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR .vxWvXRol {
  font-family: var(--CommonBold);
  font-size: 24px;
  color: var(--green-text-color);
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR .Wwls8Z5S {
  font-family: var(--CommonBold);
  font-size: 24px;
  color: var(--red-text-color);
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR .__87NEPOgK {
  display: flex;
  align-items: center;
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR .__87NEPOgK .da_8jVe9 {
  font-size: 14px;
  margin-top: 4px;
  color: var(--white-op1);
}
.DkmGxcL_ .e_VtD9tM {
  font-family: var(--CommonMedium);
  font-size: 18px;
  color: var(--white);
  margin-top: 71px;
}
.DkmGxcL_ .tr3rWJZJ {
  font-size: 15px;
  color: var(--white-op3);
  margin-top: 24px;
}
.DkmGxcL_ .axTF2kUT {
  font-size: 15px;
  color: var(--white-op3);
  margin-top: 16px;
}
.DkmGxcL_ .stAhlkKu {
  display: flex;
  margin-top: 40px;
}
.DkmGxcL_ .stAhlkKu .vPRVnCk9 {
  width: 128px;
  height: 40px;
}
.DkmGxcL_ .stAhlkKu .vPRVnCk9:nth-child(n + 2) {
  margin-left: 14px;
}
.DkmGxcL_ .GjKs0U_d {
  font-size: 12px;
  line-height: 18px;
  color: var(--white-op1);
  margin-top: 32px;
}
.ZenHhqoI {
  background: #000000;
  height: 250px;
}
.__4_yDkjGM {
  cursor: pointer;
  color: var(--white-op1);
}
.zrjZvXOZ {
  color: var(--main-color);
}
.fM7IU1sW {
  color: var(--white);
}
.r_SdalJ0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rV7TnVJB {
  border-radius: 2px;
  background: var(--card-bg);
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 16px;
}
.fGtDFjBK {
  color: var(--white-op3);
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}
.X1POtG4d {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}
.ogSsrtzB {
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.J7rNZ9hq {
  color: var(--error-text);
}

